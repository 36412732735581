import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../../layout';
import {
  HeadWrapper,
  largestContentfullPaintImg,
} from '../../layout/HeadWrapper';
import { Hero } from 'libs/growth-platform-brand-system-v2/src/templates/Hero';
import { morphism } from 'morphism';
import { schemaHero } from '../../morphismSchema/templates/schemaHero';
import { useScreenSize } from 'libs/growth-platform-brand-system-v2/src/utils/utils';
import { schemaBaseTemplate } from '../../morphismSchema/pages/schemaBaseTemplate';
import { TemplateBlocksZone } from '../../components/TemplateBlocksZone';

export const Head = ({ data, pageContext, location }: any) => {
  const heroProps = data?.datoCmsPremiumServicePage?.hero?.[0];
  const largestContentfullPaintImg: largestContentfullPaintImg =
    heroProps?.image?.gatsbyImageData?.images?.fallback ||
    heroProps?.vimeoImage?.gatsbyImageData?.images?.fallback ||
    null;
  return (
    <HeadWrapper
      data={data}
      pageContext={pageContext}
      location={location}
      templateName="PremiumServicePage"
      dataName={'datoCmsPremiumServicePage'}
      largestContentfullPaintImg={largestContentfullPaintImg}
    />
  );
};

export const query = graphql`
  query DatoCmsPremiumServicePage($locale: String, $id: String) {
    datoCmsPremiumServicePage(locale: $locale, id: { eq: $id }) {
      ...PremiumServicePage
    }
  }
`;

const PremiumServicePage = ({ data, pageContext }: any) => {
  const doc = data.datoCmsPremiumServicePage;
  const screenSize = useScreenSize();

  const _data = morphism(schemaBaseTemplate, {
    ...doc,
    screenSize,
    pageContext,
  });
  const heroData: any = morphism(schemaHero, _data?.hero);

  return (
    <Layout
      pageContext={pageContext}
      data={doc}
      templateName="PremiumServicePage"
    >
      {heroData && <Hero {...heroData} />}
      {_data?.blocks.length > 0 && (
        <TemplateBlocksZone {..._data} heroData={heroData} />
      )}
    </Layout>
  );
};

export default PremiumServicePage;
